import { useContext, useState } from 'react';
import { 
  Box,
  Input,
  NumberInput,
  NumberInputField,
  VStack,
  StackDivider,
  RadioGroup,
  Radio,
  Stack,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  InputGroup, 
  InputLeftAddon, Spinner
} from '@chakra-ui/react'

import CustomRadioGroup from './radioGroup';
import { expense_urls, CATEGORIES, STATUS } from '../../constants';
import AddIncome from './addIncome';
import BulkUpload from './bulkUpload';
import { ExpenseContext } from '../tabLayout';



function App({existingExpense = {}}) {
  const {refresh: refreshExpenses} = useContext(ExpenseContext);
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset()
  const defaultDate = new Date(currentDate.getTime() - (offset*60*1000)).toISOString().split('T')[0];

  const [name, setName] = useState(existingExpense.name || "");
  const [value, setValue] = useState(existingExpense.value || 0);
  const [date, setDate] = useState(existingExpense.date || defaultDate);
  const [sharedCard, setSharedCard] = useState(existingExpense.sharedCard || false);
  const [sharedExpense, setSharedExpense] = useState(existingExpense.sharedExpense || true);
  const [paidBy, setPaidBy] = useState(existingExpense.paidBy || 'Heather');
  const [category, setCategory] = useState(existingExpense.category || CATEGORIES.MISC.label);

  const [status, setStatus] = useState(existingExpense.status || STATUS.nothing);
  const [addIncome, setAddIncome] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);

  const parse = (val) => {
    return val.replace(/^\$/, '')
  }

  const onDateChange = (e) => {
    setDate(e.target.value);
  }

  const onSend = async () => {
    const expense = {
      name,
      value,
      date,
      sharedCard,
      paidBy,
      category,
      sharedExpense
    };
    try{
      setStatus(STATUS.pending);
      await fetch(expense_urls.POST, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(expense), // body data type must match "Content-Type" header
      });
      setStatus(STATUS.success);
      setName("");
      setValue(0);
      setDate(defaultDate);
      setCategory(CATEGORIES.MISC.label);
      refreshExpenses();
    } catch(e) {
      console.log(e);
      setStatus(STATUS.failed);
    }
    
  }

  const FeedBack = () => {
    if(status == STATUS.pending) return (
      <Alert status='info'>
        <Spinner />
        <AlertTitle ml={2}> Sending </AlertTitle>
      </Alert>
    );
    if(status == STATUS.failed) return (
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>An error occurred</AlertTitle>
      </Alert>
    );
    if(status == STATUS.success) return (
      <Alert status='success'>
        <AlertIcon />
        Expense addition succesful
      </Alert>
    );
  }
  
  if(addIncome) return <AddIncome cancel={() => setAddIncome(false)}/>
  if(bulkUpload) return <BulkUpload cancel={() => setBulkUpload(false)}/>

  
  return (
    <form>
    <Flex padding={3} justifyContent="right">
      <Button size="xs" onClick={() => setAddIncome(true)}>
        + Add Income
      </Button>
      <Button marginLeft="30px" size="xs" onClick={() => setBulkUpload(true)}>
        Bulk Upload
      </Button>
    </Flex>
    <VStack
      divider={<StackDivider borderColor='gray.200' />}
      spacing={4}
      align='stretch'
      padding={3}
    >
      <Input onChange={(e) => setName(e.target.value)} value={name} placeholder="Description" />
      <InputGroup>
        <InputLeftAddon children='$' />
          <Input
            type={'number'}
            // precision={2}
            onChange={e => {setValue(parse(e.target.value))}}
            onFocus={e => {setValue(''); e.target.value = '';}}
          >
          </Input>
      </InputGroup>
      <Input
        placeholder="Select Date and Time"
        size="md"
        value={date}
        type="date"
        onChange={onDateChange}
      />
      <Flex justifyContent="space-between">
        <Heading size="md" display="inline">Paid by</Heading>
        <RadioGroup display="inline" onChange={setPaidBy} value={paidBy}>
          <Stack direction='row'>
            <Radio value='Heather'>Heather</Radio>
            <Radio value='Tanner'>Tanner</Radio>
          </Stack>
        </RadioGroup>
      </Flex>

      <FormControl display='flex' justifyContent='space-between'>
        <FormLabel htmlFor='share-expense' mb='0'>
          <Heading size="md" display="inline">Shared Expense</Heading>
        </FormLabel>
        <Switch 
          id='share-expense'
          isChecked={sharedExpense}
          onChange={() => setSharedExpense(!sharedExpense)}
        />
      </FormControl>
      
      <CustomRadioGroup cats={CATEGORIES} selected={category} setSelected={setCategory}/>
      <Box>
        <FeedBack />
        <Box h={3}></Box>
        <Button colorScheme='blue' variant={"solid"} onClick={onSend}>Send</Button>
      </Box>
    </VStack>
    </form>
  );
}
  
  export default App;
  