import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'


import AddExpense from '../addExpense'; 
import ViewExpense from '../viewExpenses'; 
import ExpenseGraphs from '../expenseGraph'; 
import Summary from '../summary';
import Header from '../header';
import { useState, useEffect } from 'react';
import { createContext } from 'react';
import { expense_urls } from '../../constants';
import { useHash } from '../../hooks/useHash';
import useQueryParam from '../../hooks/useQueryParams';

const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

const startMonth = 5;
const startYear = 2023;

export const ExpenseContext = createContext();

function App() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const [month, setMonth] = useQueryParam('month', currentMonth);
  const [year, setYear] = useQueryParam('year', currentYear);
  const [options, setOptions] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [unfilteredExpenses, setUnfilteredExpenses] = useState([]);
  const [tabIndex, setTabIndex] = useQueryParam('tab', 0);

  useEffect(() => {
    fetchExpenses();

    const options = [];
    let i = 0;

    console.log("do it");
    let month = startMonth;
    for(let year = startYear; year <= currentYear; year++){
      console.log(year);
      for(month; month < 12 && !(year == currentYear && month > currentMonth); month++){
        console.log(month, year);
        options.push({
          month, year
        })
      }
      month = 0;
    }

    setOptions(options);
  },[]);


  const fetchExpenses = async () => {
    const resp = await fetch(expense_urls.GET);
    const val = await resp.json();
    setUnfilteredExpenses(
      val.Items
    );
  };

  useEffect( () => {
    if(!month || !year || !expenses) return;
    setExpenses(
      unfilteredExpenses.filter(item => {
        const tempMonth = parseInt(month) + 1;
        const fullMonth = tempMonth > 9 ? tempMonth : "0" + tempMonth;
        return !item.isDeleted && item.date.includes(`${year}-${fullMonth}`);
      })
    )
  }, [unfilteredExpenses, month, year]);
  

  if(!options || !month) return null;

  const handleMonthChange = (date) => {
    setMonth(date.month);
    setYear(date.year);
  }


  return( 
    <Box>
      <ExpenseContext.Provider value={{expenses, setExpenses, refresh: fetchExpenses}}>
        <Header setMonth={handleMonthChange} month={{month, year}} options={options}></Header>
        <Tabs index={parseInt(tabIndex)} onChange={setTabIndex} variant='soft-rounded' colorScheme='beige'>
          <TabList margin={4} >
            <Tab>Add</Tab>
            <Tab>List</Tab>
            <Tab>Track</Tab>
            <Tab>Summary</Tab>
          </TabList>
        
          <TabPanels>
            <TabPanel>
              <AddExpense />
            </TabPanel>
            <TabPanel>
              <ViewExpense />
            </TabPanel>
            <TabPanel>
              <ExpenseGraphs />
            </TabPanel>
            <TabPanel>
              <Summary />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ExpenseContext.Provider>
    </Box>
   
  )
}

export default App;