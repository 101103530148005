export default {
  colors: {
    beige: {
      "50": "#F8F2ED",
      "100": "#EBDACB",
      "200": "#DFC3AA",
      "300": "#D2AB89",
      "400": "#C59468",
      "500": "#B87C47",
      "600": "#946338",
      "700": "#6F4A2A",
      "800": "#4A321C",
      "900": "#25190E"
    },
    "pink": {
      "50": "#FAEEEA",
      "100": "#F1D1C5",
      "200": "#E9B3A0",
      "300": "#E0957B",
      "400": "#D77756",
      "500": "#CE5931",
      "600": "#A54727",
      "700": "#7C351D",
      "800": "#532413",
      "900": "#29120A"
    },
    red: "#ce796b",
    brown: "#c18c5d",
    blue: {
      "50": "#F0F2F4",
      "100": "#D5DBE1",
      "200": "#BAC4CE",
      "300": "#9FADBB",
      "400": "#8596A8",
      "500": "#6A7F95",
      "600": "#556677",
      "700": "#3F4C5A",
      "800": "#2A333C",
      "900": "#15191E"
    }
  }
}