import { Line, Pie, measureTextWidth } from '@ant-design/plots';
import { Card, Heading, VStack } from '@chakra-ui/react';

import { SAVING_CATEGORIES } from '../../constants';


let values = [];

Object.keys(SAVING_CATEGORIES).forEach(cat => {
  const category = SAVING_CATEGORIES[cat];
  for(let i = 0; i < 10; i++){
    const month = `0${Math.floor(Math.random() * 3) + 6}`;
    const day = Math.floor(Math.random() * 18) + 10;
    values.push({
      category: category.label,
      date: `2023-${month}-${day}`,
      value: Math.floor(Math.random()*2000) - 100,
      paidBy: Math.random() > 0.5 ? 'Tanner' : 'Heather'
    });
  }
})

values = values.map(val => ({...val, date: new Date(val.date)}));
values = values.sort((a, b) => a.date - b.date);

const sums = {};
const accountSums = {Heather: {}, Tanner: {}};

values = values.map(val => {
  if(!sums[val.category]) sums[val.category] = 0;
  sums[val.category] += val.value;
  return {
    ...val,
    value: sums[val.category]
  }
})

values.forEach(val => {
  if(val.category == SAVING_CATEGORIES.STUDENT_LOAN.label) return;
  if(!accountSums[val.paidBy][val.category]) accountSums[val.paidBy][val.category] = 0;
  accountSums[val.paidBy][val.category] += val.value;
})

const valuesWithoutLoans = values.filter(val => val.category != SAVING_CATEGORIES.STUDENT_LOAN.label)
const loanValues = values
  .filter(val => val.category == SAVING_CATEGORIES.STUDENT_LOAN.label)
  .map(val => ({...val, value: 10000-val.value}));
console.log(sums);

const LineGraph = ({data}) => {
  const config = {
    data,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    xAxis: {
      type: 'time'
    },
    stepType: 'hvh',
    color: ['#1979C9', '#D62A0D', '#FAA219'],
  };

  return <Line {...config} />;
}

const Donut = ({values}) => {

  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  }

  const data = [];
  Object.keys(values).forEach(cat => {
    data.push({type: cat, value: values[cat]})
  })

  console.log(data);

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `$${v}`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : 'Total';
          return renderStatistic(d, text, {
            fontSize: 28,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '32px',
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum ? `$ ${datum.value}` : `$ ${data.reduce((r, d) => r + d.value, 0)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };
  return <Pie {...config} />;
}

export default () => (
  <VStack spacing={10}>
    <Card width="90%" p={30}>
      <Heading>All Savings</Heading>
      <LineGraph data={valuesWithoutLoans}/>
    </Card>
    <Card width="90%" p={30}>
      <Heading>Loan Balance</Heading>
      <LineGraph data={loanValues}/>
    </Card>
    <Card width="90%" p={30}>
      <Heading>Heather's Account</Heading>
      <Donut values={accountSums.Heather} />
    </Card>
    <Card width="90%" p={30}>
      <Heading>Tanner's Account</Heading>
      <Donut values={accountSums.Tanner} />
    </Card>
  </VStack>
)