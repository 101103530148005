import { 
  Radio, 
  RadioGroup,
  Box,
  useRadio,
  useRadioGroup,
  Grid,
  GridItem
} from '@chakra-ui/react'



// 1. Create a component that consumes the `useRadio` hook
function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <GridItem rowSpan={1} colSpan={1} as='label'>
      <Box as='label'>
        <input {...input} />
        <Box
          {...checkbox}
          cursor='pointer'
          borderWidth='1px'
          borderRadius='md'
          boxShadow='md'
          _checked={{
            bg: 'blue.600',
            color: 'white',
            borderColor: 'blue.600',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          px={5}
          py={3}
        >
          {props.children}
        </Box>
      </Box>
    </GridItem>
  )
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
function Example({cats, setSelected}) {
  const options = Object.keys(cats).map(val => cats[val].label);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: options[0],
    onChange: setSelected
  })

  const group = getRootProps()

  return (
    <Grid {...group} 
      templateRows='repeat(4, 1fr)'
      templateColumns='repeat(2, 1fr)'
      gap={4}
    >
      {options.map((value) => {
        const radio = getRadioProps({ value })
        return (
          <RadioCard key={value} {...radio}>
            {value}
          </RadioCard>
        )
      })}
    </Grid>
  )
}

export default Example;