
import { RingProgress } from '@ant-design/plots';
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button
} from '@chakra-ui/react'
import { useContext } from 'react';
import { useEffect, useRef, useState } from 'react';
import { expense_urls, CATEGORIES } from '../../constants';
import ExpenseTable from '../expenseTable';
import { ExpenseContext } from '../tabLayout';

function App() {
  const { expenses } = useContext(ExpenseContext);

  return (
    <div className="App">
      {Object.keys(CATEGORIES).map(key => (
        <Category key={CATEGORIES[key].label} label={CATEGORIES[key].label} budget={CATEGORIES[key].budget} expenses={expenses}/>
      ))}
    </div>
  );
}

function Category({label, expenses, budget}) {
  const ref = useRef();

  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const spent = expenses.reduce((acc, expense) => {
    if(expense.category !== label) return acc;
    return acc + parseFloat(expense.value);
  }, 0) || 0;


  const config = {
    height: 100,
    width: 100,
    autoFit: false,
    percent: spent/budget,
    color: ['#495867', '#ecc8af'],
  };

  return (
    <Box 
      width="100%" 
      height="100%"
      overflowY="auto"
      padding={4} 
      boxSizing="border-box"
    >
      <Flex 
        width="100%" 
        justifyContent="space-between" 
        alignItems="center" 
        padding={4}
        boxSizing="border-box"
        borderRadius={4}
        border="1px solid rgb(100,100,100,0.3)"
        onClick={onOpen}
      >
        <Box>
          <Stat>
            <StatLabel>{label}</StatLabel>
            <StatNumber>${spent.toFixed(2)}</StatNumber>
            <StatHelpText alignItems="right">
              / ${budget}
            </StatHelpText>
          </Stat>
        </Box>
        <RingProgress {...config} />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent ref={ref}>
          <ModalHeader>{label}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ExpenseTable expenses={expenses.filter(expense => expense.category === label)}  modalRef={ref}/>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
          
    </Box>
  )

  
}

export default App;
  