import { useEffect, useState } from "react";

import {
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Button, Text, Alert, AlertIcon,
  Box,
  Input,
  NumberInput,
  NumberInputField,
  VStack,
  StackDivider,
  RadioGroup,
  Radio,
  Stack,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  AlertTitle,
  AlertDescription,
  InputGroup, 
  InputLeftAddon, Select
} from '@chakra-ui/react'
import { EditIcon } from "@chakra-ui/icons";
import { CATEGORIES, expense_urls, STATUS } from "../../constants";

function ExpenseRow({expense, deleteExpense, displayColumns, startEdit, endEdit}) {
  const [isEditing, setIsEditing] = useState(false);

  const edit = (e) => {
    e.preventDefault();
    setIsEditing(true);
    startEdit();
  }

  const done = (expense) => {
    endEdit(expense);
    setIsEditing(false);
  }

  if(isEditing) return <EditableExpenseColumn existingExpense={expense} done={done}/>

  return (
    <Popover>
      <PopoverTrigger>
        <Tr bg={expense.paidBy === 'Tanner' ? 'blue.100' : 'pink.100'}>
          {displayColumns['name'] && <Td>{expense.name}</Td> }
          {displayColumns['category'] && <Td>{expense.category}</Td> }
          {displayColumns['date'] && <Td>{expense.date}</Td> }
          {displayColumns['value'] && <Td isNumeric>${parseFloat(expense.value).toFixed(2)}</Td> }
          {displayColumns['paidBy'] && <Td>{expense.paidBy}</Td> }
          {displayColumns['sharedCard'] && <Td textAlign="center">{expense.sharedCard ? "yes" : "no"}</Td> }
          {displayColumns['sharedExpense'] && <Td textAlign="center">{expense.sharedExpense ? "yes" : "no"}</Td> }
          {displayColumns['edit'] &&<Td><EditIcon onClick={edit} /></Td> }
        </Tr>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
            <PopoverHeader>{expense.name}</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
          <Table size='sm'>
            <Tbody>
              <Tr>
                <Td>Amount</Td>
                <Td>${expense.value}</Td>
              </Tr>
              <Tr>
                <Td>paid by</Td>
                <Td>{expense.paidBy}</Td>
              </Tr>
              <Tr>
                <Td>date</Td>
                <Td>{expense.date}</Td>
              </Tr>
              <Tr>
                <Td>shared card</Td>
                <Td>{expense.sharedCard ? "yes" : "no"}</Td>
              </Tr>
              <Tr>
                <Td>shared expense</Td>
                <Td>{expense.sharedExpense ? "yes" : "no"}</Td>
              </Tr>
              <Tr>
                <Td>category</Td>
                <Td>{expense.category}</Td>
              </Tr>
              <Tr>
                <Td>is deleted</Td>
                <Td>{expense.isDeleted ? "yes" : "no"}</Td>
              </Tr>
            </Tbody>
          </Table>
          </PopoverBody>
          <PopoverFooter>
            {deleteExpense != null && <Button onClick={() => deleteExpense(expense.id)} colorScheme='beige'>Delete</Button>}
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

const EditableExpenseColumn = ({existingExpense, done}) => {
  const [name, setName] = useState(existingExpense.name || "");
  const [value, setValue] = useState(existingExpense.value ||'');
  const [date, setDate] = useState(existingExpense.date);
  const [sharedCard, setSharedCard] = useState(existingExpense.sharedCard || false);
  const [sharedExpense, setSharedExpense] = useState(existingExpense.sharedExpense || false);
  const [paidBy, setPaidBy] = useState(existingExpense.paidBy || 'Heather');
  const [category, setCategory] = useState(existingExpense.category || CATEGORIES.MISC.label);
  const [status, setStatus] = useState(existingExpense.status || STATUS.pending);

  const parse = (val) => {
    return val.replace(/^\$/, '')
  }


  const onDateChange = (e) => {
    const nextDate = e.target.value
    setDate(nextDate);
  }

  const onSave = async () => {
    const expense = {
      name,
      value,
      date,
      sharedCard,
      paidBy,
      category,
      sharedExpense
    };
    try{
      setStatus(STATUS.pending);
      await fetch(`${expense_urls.PUT}/${existingExpense.id}`, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(expense), // body data type must match "Content-Type" header
      });
      setStatus(STATUS.success);
    } catch(e) {
      console.log(e);
      setStatus(STATUS.failed);
    }
    
  }

  const FeedBack = () => {
    if(status == STATUS.pending) return (
      <Box>
        <Button size="xs" colorScheme='blue' variant={"solid"} onClick={onSave}>Save</Button>
      </Box>
    );
    if(status == STATUS.failed) return (
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>An error occurred</AlertTitle>
      </Alert>
    );
    if(status == STATUS.success) {
      setTimeout(() => {
        done({
          name,
          value,
          date,
          sharedCard,
          paidBy,
          category,
          sharedExpense,
          id: existingExpense.id
        })
      }, 1000);
      return (
        <Alert status='success'>
          <AlertIcon />
        </Alert>
      );
    }
  }

  return (
    <tr
    >
      <td><Input size="xs" onChange={(e) => setName(e.target.value)} value={name} placeholder="Description" /></td>
      <td>
        <Select size="xs" value={category} onChange={e => setCategory(e.target.value)}>
          {
            Object.keys(CATEGORIES).map(i => CATEGORIES[i].label).map(category => 
              <option key={category} value={category}>{category}</option>
            )
          }
        </Select>
      </td>
      <td>
        <Input
          size="xs"
          value={date}
          type="date"
          onChange={onDateChange}
        />
      </td>

      <td>
        <InputGroup size="xs">
          <InputLeftAddon children='$' />
            <Input
              type={'number'}
              // precision={2}
              onChange={e => {setValue(parse(e.target.value))}}
              value={value}
            >
            </Input>
        </InputGroup>
      </td>

      <td>
        <Select size="xs" value={paidBy} onChange={e => setPaidBy(e.target.value)}>
          <option value={'Tanner'}>Tanner</option>
          <option value={'Heather'}>Heather</option>
        </Select>
      </td>

      <td>
        <FormControl display='flex' justifyContent='center'>

          <Switch 
            id='hare-cards'
            isChecked={sharedCard}
            onChange={() => setSharedCard(!sharedCard)}
          />
          <FormLabel htmlFor='share-card' mb='0'>
          </FormLabel>
        </FormControl>
      </td>

      <td>
        <FormControl display='flex' justifyContent='center'>
          <Switch 
            id='share-expense'
            isChecked={sharedExpense}
            onChange={() => setSharedExpense(!sharedExpense)}
          />
          <FormLabel htmlFor='share-expense' mb='0'>
          </FormLabel>
        </FormControl>
      </td>
      
      <td>
        <FeedBack/>
      </td>
    </tr>
  );
};
  
export default ExpenseRow;
