import { CheckIcon, WarningIcon } from "@chakra-ui/icons";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Code, Flex, Heading, Input, Radio, RadioGroup, Select, Spinner, Stack, Switch, Table, TableContainer, Tbody, Td, Textarea, Th, Thead, Tr } from "@chakra-ui/react"
import { useState } from "react";
import { CATEGORIES, expense_urls } from "../../constants";

export default ({cancel}) => {
  const [tableInput, setTableInput] = useState("");
  const [cleanedData, setCleanedData] = useState([]);
  const [mapping, setMapping] = useState([]);
  const [paidBy, setPaidBy] = useState("Heather");
  
  const handleChange = e => {
    const nextTableInput = e.target.value;
    setTableInput(nextTableInput);
    let tableData = [];
    if(nextTableInput.length > 0){
      const rows = nextTableInput.split('\n');
      tableData = rows.map(row => row.split('\t'));
    }
    const tableObjects = [];
    for(let i = 0; i < tableData.length; i++){
      tableObjects.push({
        arrayData: tableData[i],
        sharedExpense: true,
        category: CATEGORIES.FOOD.label,
        include: true
      })
    }
    setCleanedData(tableObjects);
    if(tableData?.length) {
      const newMapping = [];
      for(let i = 0; i < tableData[0].length; i++){
        newMapping.push("name");
      }
      setMapping(newMapping);
    }
  }
  

  const changeColumnMapping = index => name => {
    setMapping(old => {
      const next = [...old];
      next[index] = name;
      return next;
    })
  }

  const handleValueChange = (row) => (index) => (e) => {
    if(index === "include" || index === "sharedExpense"){
      setCleanedData(prev => {
        const next = JSON.parse(JSON.stringify(prev));
        next[row][index] = e.target.checked;
        return next;
      })
      return;
    }
    if(index === "category"){
      setCleanedData(prev => {
        const next = JSON.parse(JSON.stringify(prev));
        next[row][index] = e.target.value;
        return next;
      })
    }
    setCleanedData(prev => {
      const next = JSON.parse(JSON.stringify(prev));
      next[row].arrayData[index] = e.target.value;
      return next;
    })
  }

  return (
    <Box>
      <Flex padding={3} justifyContent="right">
        <Button size="xs" onClick={cancel}>
          Add Single Expense
        </Button>
      </Flex>
      <Heading mb={3}>
        Bulk Upload
      </Heading>
      <Textarea 
        placeholder="Copy and paste from an excel sheet to begin"
        value={tableInput}
        onChange={handleChange}
      ></Textarea>
      <Flex marginY="30px" alignContent="center">
        <Heading marginRight="50px" size="md" display="inline">Paid by</Heading>
        <RadioGroup display="inline" onChange={setPaidBy} value={paidBy}>
          <Stack direction='row'>
            <Radio value='Heather'>Heather</Radio>
            <Radio value='Tanner'>Tanner</Radio>
          </Stack>
        </RadioGroup>
      </Flex>
      <Table __css={{'table-layout': 'fixed', width: 'full'}}>
        <Thead>
          <Th w="108px">
            Include
          </Th>
          {mapping.map((val,index) => {
            return <SelectType
              onChange={changeColumnMapping(index)}
              value={val}
            />
          })}
          <Th w="112px">
            Shared Expense
          </Th>
          <Th w="150px">
            Category
          </Th>
        </Thead>
        <Tbody>
          {cleanedData.map((row, index) => 
            <Row row={row} mapping={mapping} handleChange={handleValueChange(index)}></Row>
          )}
        </Tbody>
      </Table>
      <ProcessTableDate data={cleanedData} mapping={mapping} paidBy={paidBy} />
    </Box>
  )
}

function Row({row, mapping, handleChange}){
  return (
    <Tr>
      <Td w={2}>
        <Switch isChecked={row.include} onChange={handleChange('include')}></Switch>
      </Td>
      {row.arrayData.map((data, i) => 
        <ValueInput data={data} dataType={mapping[i]} handleChange={handleChange(i)}/>
      )}
      <Td w={2}>
        <Switch isChecked={row.sharedExpense} onChange={handleChange('sharedExpense')}></Switch>
      </Td>
      <Td w={2}>
        <Select size={"xs"} value={row.category} onChange={handleChange('category')}>
          {Object.keys(CATEGORIES).map(cat => (
            <option value={CATEGORIES[cat].label}>
              {CATEGORIES[cat].label}
            </option>
          ))}
        </Select>
      </Td>
    </Tr>
  )
}

function ValueInput({data, dataType, handleChange}){
  if(dataType === "hide"){
    return null
  }
  if(dataType === "name"){
     return <Td><Textarea size="sm" value={data} onChange={handleChange}/></Td>
  }
  if(dataType === "value"){
    const val = data.replace('$', '').replace(',','');
    return(
      <Td>
        <Input size="sm" type="number" value={val} onChange={handleChange}>
        </Input>
      </Td>
    )
  }
  if(dataType === "date"){
    const yourDate = new Date(data);
    const offset = yourDate.getTimezoneOffset();
    const date = new Date(yourDate.getTime() - (offset*60*1000));
    const dateString = yourDate.toISOString().split('T')[0];

    return <Td>
      <Input
        size="sm"
        value={dateString}
        type="date"
        onChange={handleChange}
      />
    </Td>
  }
  return <Td>{data}</Td>
}

function SelectType({onChange, value}){
  const fields = [
    "name",
    "value",
    "date",
    "ignore",
    "hide",
  ]

  const handleChange = (e) => {
    onChange(e.target.value);
  }
  if(value === 'hide') return null;
  return (
    <Td>
      <Select value={value} onChange={handleChange}>
        {fields.map(field => (
          <option value={field}>{field}</option>
        ))}
      </Select>
    </Td>
  )
}

function ProcessTableDate({data, mapping, paidBy}){
  const [status, setStatus] = useState();

  const handleSubmit = async (preparedData) => {
    const nextStatuses = [];
    for(let i = 0; i < preparedData.length; i++){
      nextStatuses.push("pending");
    }
    setStatus(nextStatuses);
    for(let i = 0; i < preparedData.length; i++){
      let nextStatus = "success";
      try{
        await fetch(expense_urls.POST, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(preparedData[i]), // body data type must match "Content-Type" header
        });
      } catch(e) {
        console.log(e);
        nextStatus = "error";
      }
      setStatus(prev => {
        const next = prev ? [...prev]: nextStatuses;
        next[i] = nextStatus;
        return next;
      })
    }
  }

  const missing = [];
  if(!mapping.includes("date")) missing.push("date");
  if(!mapping.includes("name")) missing.push("name");
  if(!mapping.includes("value")) missing.push("value");

  if(missing.length > 0)
    return <Box><Alert status='warning'>
      <AlertIcon />
      <AlertTitle>Please select the require fields</AlertTitle>
      <AlertDescription>Missing fields: {missing.toString()}</AlertDescription>
    </Alert></Box>;

  const preparedData = [];
  for(let i = 0; i < data.length; i++){
    if(!data[i].include) continue;
    const expense = {
      sharedExpense: data[i].sharedExpense,
      category: data[i].category,
      paidBy: paidBy,
    };
    for(let j = 0; j < mapping.length; j++){
      if(mapping[j] === "ignore" || mapping[j] === "hide") continue;
      if(mapping[j] === "value"){
        expense.value = data[i].arrayData[j].replace('$', '').replace(',','');
        continue;
      }
      if(mapping[j] === "date"){
        const yourDate = new Date(data[i].arrayData[j]);
        const offset = yourDate.getTimezoneOffset();
        const date = new Date(yourDate.getTime() - (offset*60*1000));
        const dateString = yourDate.toISOString().split('T')[0];
        expense.date = dateString;
        continue;
      }
      expense[mapping[j]] = data[i].arrayData[j];
    }
    preparedData.push(expense);
  }
  const StatusIndicator = ({status}) => 
    <Box>
      <Heading>
        Upload Status
      </Heading>
      <Table>
        <Tbody>
          {preparedData.map((data, i) => (
            <Tr>
              <Td>{data.name}</Td>
              <Td>
                {status[i] == "pending" && <Spinner size="sm" />}
                {status[i] == "success" && <CheckIcon color="green.600" size="sm" />}
                {status[i] == "error" && <WarningIcon color="red" size="sm" />}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>

  return <Stack spacing="20px" marginBottom="500px">
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex='1' textAlign='left'>
              Preview Items
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Code>
            <pre>{JSON.stringify(preparedData, null, 4)}</pre>
          </Code>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    
    <Flex>
      {
        status ? 
          <StatusIndicator status={status} /> 
          : 
          <Button onClick={() => handleSubmit(preparedData)}>Submit</Button>
      }
    </Flex>
  </Stack>
}