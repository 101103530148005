import { useContext, useState } from 'react';
import { 
  Box,
  Input,
  NumberInput,
  NumberInputField,
  VStack,
  StackDivider,
  RadioGroup,
  Radio,
  Stack,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  InputGroup, 
  InputLeftAddon
} from '@chakra-ui/react'

import CustomRadioGroup from './radioGroup';
import { expense_urls, CATEGORIES, STATUS } from '../../constants';
import { ExpenseContext } from '../tabLayout';




function App({existingExpense = {}, cancel}) {
  const {refresh: refreshExpenses} = useContext(ExpenseContext);

  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset()
  const defaultDate = new Date(currentDate.getTime() - (offset*60*1000)).toISOString().split('T')[0];

  const [name, setName] = useState(existingExpense.name || "");
  const [value, setValue] = useState(existingExpense.value ||'');
  const [date, setDate] = useState(existingExpense.date || defaultDate);
  const [paidBy, setPaidBy] = useState(existingExpense.paidBy || 'Heather');

  const [status, setStatus] = useState(existingExpense.status || STATUS.pending);

  const parse = (val) => {
    return val.replace(/^\$/, '')
  }

  const onDateChange = (e) => {
    setDate(e.target.value);
  }

  const onSend = async () => {
    const expense = {
      name,
      value: value*-1,
      date,
      paidBy,
      isIncome: true,
      category: CATEGORIES.isIncome
    };
    try{
      setStatus(STATUS.pending);
      await fetch(expense_urls.POST, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(expense), // body data type must match "Content-Type" header
      });
      setStatus(STATUS.success);
      refreshExpenses();
    } catch(e) {
      console.log(e);
      setStatus(STATUS.failed);
    }
    
  }

  const FeedBack = () => {
    if(status == STATUS.pending) return null;
    if(status == STATUS.failed) return (
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>An error occurred</AlertTitle>
      </Alert>
    );
    if(status == STATUS.success) return (
      <Alert status='success'>
        <AlertIcon />
        Expense addition succesful
      </Alert>
    );
  }


  return (
    <form>
    <Flex padding={3} justifyContent="right">
      <Button size="xs" onClick={cancel}>
        + Add Expense
      </Button>
    </Flex>
    <VStack
      divider={<StackDivider borderColor='gray.200' />}
      spacing={4}
      align='stretch'
      padding={3}
    >
      <Input onChange={(e) => setName(e.target.value)} value={name} placeholder="Description" />
      <InputGroup>
        <InputLeftAddon children='$' />
          <Input
            type={'number'}
            // precision={2}
            onChange={e => {setValue(parse(e.target.value))}}
            onFocus={e => {setValue(''); e.target.value = '';}}
          >
          </Input>
      </InputGroup>
      <Input
        placeholder="Select Date and Time"
        size="md"
        value={date}
        type="date"
        onChange={onDateChange}
      />
      <Flex justifyContent="space-between">
        <Heading size="md" display="inline">Paid to</Heading>
        <RadioGroup display="inline" onChange={setPaidBy} value={paidBy}>
          <Stack direction='row'>
            <Radio value='Heather'>Heather</Radio>
            <Radio value='Tanner'>Tanner</Radio>
          </Stack>
        </RadioGroup>
      </Flex>

     <Box>
        <Button colorScheme='blue' variant={"solid"} onClick={onSend}>Send</Button>
      </Box>
      <FeedBack />
    </VStack>
    </form>
  );
}
  
  export default App;
  