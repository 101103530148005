import { Heading, Box, Flex, Select } from '@chakra-ui/react'
import { Link } from 'react-router-dom';


function App() {


  return( 
    <Flex bg="blue.400" p={4} color="white" justifyContent="space-between" alignItems="center">
      <Flex alignItems="baseline">
        <Heading size="md">
          BudgUp
        </Heading>
        <Link to='/'>
          <Heading ml={4} size="sm">
            Expense
          </Heading>
        </Link>
        <Link to='/save'>
          <Heading ml={4} size="sm">
            Save
          </Heading>
        </Link>
      </Flex>
      <Box>
      </Box>
    </Flex>
   
  )
}

export default App;