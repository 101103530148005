import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'


import AddSavings from '../addSaving'; 
import TrackSavings from '../track'; 
import Header from '../header';
import { useState, useEffect } from 'react';
import { createContext } from 'react';
import { expense_urls } from '../../constants';
import useQueryParam from '../../hooks/useQueryParams';

function App() {

  const [tabIndex, setTabIndex] = useQueryParam('tab', 0);

  useEffect(() => {
    fetchExpenses();
  },[]);


  const fetchExpenses = async () => {
    const resp = await fetch(expense_urls.GET);
    const val = await resp.json();
  };

  return( 
    <Box>
      <Header></Header>
      <Tabs index={parseInt(tabIndex)} onChange={setTabIndex} variant='soft-rounded' colorScheme='blue'>
        <TabList margin={4} >
          <Tab>Add</Tab>
          <Tab>Track</Tab>
          {/* <Tab>List</Tab>
          <Tab>Track</Tab>
          <Tab>Summary</Tab> */}
        </TabList>
      
        <TabPanels>
          <TabPanel>
            <AddSavings />
          </TabPanel>
          <TabPanel>
            <TrackSavings />
          </TabPanel>
          {/* <TabPanel>
            <ViewExpense />
          </TabPanel>
          <TabPanel>
            <ExpenseGraphs />
          </TabPanel>
          <TabPanel>
            <Summary />
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </Box>
   
  )
}

export default App;