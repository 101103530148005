import { Heading, Box, Flex, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

function App({month: {month, year}, setMonth, options}) {
  const handleMonthChange = (val) => {
    const month = parseInt(val.split('/')[0]);
    const year = parseInt(val.split('/')[1]);
    return setMonth({month, year})
  }

  return( 
    <Flex bg="red" p={4} color="white" justifyContent="space-between" alignItems="center">
      <Flex alignItems="baseline">
        <Heading size="md">
          BudgUp
        </Heading>
        <Link to='/'>
          <Heading ml={4} size="sm">
            Expense
          </Heading>
        </Link>
        <Link to='/save'>
          <Heading ml={4} size="sm">
            Save
          </Heading>
        </Link>
      </Flex>
      <Box>
      <Select value={`${month}/${year}`} onChange={e => handleMonthChange(e.target.value)}>
        {options.map(({month, year}) => 
          <option value={`${month}/${year}`} key={`${month}/${year}`}>{months[month]} {year}</option>
        )}
      </Select>
      </Box>
    </Flex>
   
  )
}

export default App;