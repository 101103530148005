export const expense_urls = {
    POST: "https://weu11u00n3.execute-api.us-west-2.amazonaws.com/dev/expense",
    DELETE: "https://weu11u00n3.execute-api.us-west-2.amazonaws.com/dev/expense",  
    GET: "https://weu11u00n3.execute-api.us-west-2.amazonaws.com/dev/expense",
    PUT: "https://weu11u00n3.execute-api.us-west-2.amazonaws.com/dev/expense"
}

export const CATEGORIES = {
  MISC: {
    label: "Miscellaneous",
    budget: 0,
  },
  FIXED: {
    label: "Fixed Expenses",
    budget: 6400
  },
  SUBSCRIPTIONS: {
    label: "Subscriptions",
    budget: 92
  },
  EAT_OUT_COMBINED: {
    label: "Eat out - shared",
    budget: 450,
  },
  EAT_OUT_TANNER: {
    label: "Eat out - Tanner",
    budget: 100,
  },
  EAT_OUT_HEATHER: {
    label: "Eat out - Heather",
    budget: 100,
  },
  ALCOHOL: {
    label: "Alcohol",
    budget: 100,
  },
  ENTERTAINMENT: {
    label: "Entertainment",
    budget: 200,
  },
  CAR: {
    label: "Gas / Car",
    budget: 300,
  },
  FOOD: {
    label: "Food",
    budget: 828,
  },
  HEALTH: {
    label: "Health",
    budget: 200
  },
  ALOE: {
    label: "Aloe",
    budget: 300,
  },
  TRAVEL: {
    label: "Travel",
    budget: 0
  },
  HOME: {
    label: "Home Improvement",
    budget: 0,
  },
  INCOME: {
    label: "Income",
    budget: 0,
  },
}

export const STATUS = {
  pending: "pending",
  failed: "failed",
  success: "success",
  nothing: "nothing",
}

export const SAVING_CATEGORIES = {
  CAR: {
    label: "Car"
  },
  HOME: {
    label: "Home"
  },
  TRAVEL: {
    label: "Travel",
  },
  PERSONAL: {
    label: "Personal",
  },
  STUDENT_LOAN: {
    label: "Student Loan",
  },
}
