import { useEffect, useRef, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Button, Text, Icon,
  Box, FormControl, FormLabel, Heading, Switch, Modal, ModalBody, useDisclosure, ModalContent, ModalHeader, ModalOverlay, Flex, Select, Stack
} from '@chakra-ui/react';
import {
  HamburgerIcon
} from '@chakra-ui/icons';
import ExpenseRow from './expenseRow';

function App({expenses: unorderedExpenses, deleteExpense, noControls, setExpenses: setUnorderedExpenses }) {
  const [sortBy, setSortBy] = useState({category: "date", order: 1});
  const [displayColumns, setDisplayColumns] = useState({name: true, category: false, date: true, value: true});
  const [paidBy, setPaidBy] = useState("*");
  const [payer, setPayer] = useState("*");
  const [editing, setEditing] = useState(0);
  const [lastDisplayState, setLastDisplayState] = useState();

  const startEdit = () => {
    const initialEditing = editing;
    setEditing(last => last + 1);
    if(initialEditing === 1) return;
    setLastDisplayState(displayColumns);
    setDisplayColumns({name: true, edit: true, category: true, date: true, value: true, paidBy: true, sharedCard: true, sharedExpense: true})
  }

  const endEdit = (newExpense) => {
    setUnorderedExpenses(old => {
      const next = [...old];
      const index = next.findIndex(exp => newExpense.id === exp.id);
      next[index] = newExpense;
      return next;
    })
    const initialEditing = editing;
    setEditing(last => last - 1);
    if(initialEditing !== 1) return;
    setDisplayColumns(lastDisplayState)
  }
  // const [expenses, setExpenses] = useState(unorderedExpenses);

  const setSortCategory = (category) => {
    if(category === sortBy.category) setSortBy({category, order: sortBy.order * -1});
    else setSortBy({category, order: 1});
  }

  let expenses = unorderedExpenses;
  if(sortBy.category === "date") expenses.sort((a, b) => {
    return (new Date(a.date) - new Date(b.date)) * sortBy.order;
  });
  else if(sortBy.category === "value") expenses.sort((a, b) => (a[sortBy.category] - b[sortBy.category]) * sortBy.order);
  else expenses.sort((a, b) => a[sortBy.category] > b[sortBy.category] ? sortBy.order : sortBy.order * -1);
  
  const SortableTableHeader = ({category, children, isNumeric}) => {
  const arrow = category === sortBy.category && <>{sortBy.order < 0 ? ' ↑' : ' ↓'}</>
    return <Th isNumeric={isNumeric} onClick={() => setSortCategory(category)}>{children}{arrow}</Th>;
  }

  if(paidBy != '*') expenses = expenses.filter(expense => expense.paidBy === paidBy)
  if(payer !== '*') expenses = expenses.filter(expense => {
    if(payer === 'Shared') return expense.sharedExpense;
    return !expense.sharedExpense && expense.paidBy === payer;
  })
  
  return (
    <Box>
      <Box>
        <Controls displayColumns={displayColumns} setDisplayColumns={setDisplayColumns} paidBy={paidBy} setPaidBy={setPaidBy} payer={payer} setPayer={setPayer}/>
      </Box>
      <TableContainer >
        <Table size='sm'>
          <Thead>
            <Tr>
              {displayColumns['name'] && <SortableTableHeader category="name">Name</SortableTableHeader> }
              {displayColumns['category'] && <SortableTableHeader category="category">Category</SortableTableHeader> }
              {displayColumns['date'] && <SortableTableHeader category="date">Date</SortableTableHeader> }
              {displayColumns['value'] && <SortableTableHeader isNumeric category="value">Amount</SortableTableHeader> }
              {displayColumns['paidBy'] && <Th>paidBy</Th> }
              {displayColumns['sharedCard'] && <Th>sharedCard</Th> }
              {displayColumns['sharedExpense'] && <Th>sharedExpense</Th> }
              {displayColumns['edit'] && <Th>edit</Th> }
            </Tr>
          </Thead>
          <Tbody>
            {!expenses.length && <Tr><Td colSpan={3}> No Data </Td></Tr>}
            {expenses.map(expense => <ExpenseRow startEdit={startEdit} endEdit={endEdit} expense={expense} deleteExpense={deleteExpense} displayColumns={displayColumns}/>)}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Controls({displayColumns, setDisplayColumns, paidBy, setPaidBy, payer, setPayer}){
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box padding={4} width="fit-content">
      <Button colorScheme='blue' onClick={onOpen}>
        <HamburgerIcon /> <Text marginLeft="2">Settings</Text>
      </Button>      
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Settings</ModalHeader>
          <ModalBody>
            <Flex>
              <Stack spacing={3} flex={1}  p={4}>
                <Text>Display Columns:</Text>
                {['paidBy', 'value', 'date', 'sharedCard', 'sharedExpense', 'category', 'edit'].map(column => 
                  <ColumnSwitch key={column }column={column} displayColumns={displayColumns} setDisplayColumns={setDisplayColumns} />
                )}
              </Stack>
              <Stack spacing={3} flex={1}  p={4}>
                <Text>Paid by:</Text>
                <Select value={paidBy} onChange={e => setPaidBy(e.target.value)}>
                  <option value={'Tanner'}>Tanner</option>
                  <option value={'Heather'}>Heather</option>
                  <option value={'*'}>Either</option>
                </Select>
                <Text>Whos paying:</Text>
                <Select value={payer} onChange={e => setPayer(e.target.value)}>
                  <option value={'Tanner'}>Tanner</option>
                  <option value={'Heather'}>Heather</option>
                  <option value={'Shared'}>Shared</option>
                  <option value={'*'}>Any</option>
                </Select>
              </Stack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
};

function ColumnSwitch({column, displayColumns, setDisplayColumns}) {
  return <FormControl display='flex' justifyContent='space-between'>
    <FormLabel htmlFor={`${column}_checkbox`} mb='0'>
      <Text size="sm" display="inline">{column}</Text>
    </FormLabel>
    <Switch 
      marginLeft={5}
      id={`${column}_checkbox`}
      isChecked={displayColumns[column] || false}
      onChange={() => setDisplayColumns(old => {
        return {...old, [column]: !old[column]};
      })}
    />
  </FormControl>;
}

export default App;



  