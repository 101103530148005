import { useEffect, useState } from "react";

import ExpenseTable from '../expenseTable';

import { expense_urls } from "../../constants";
import { ExpenseContext } from "../tabLayout";
import { useContext } from "react";

function App() {
  const { expenses, setExpenses } = useContext(ExpenseContext);

  const deleteExpense = async (id) => {
    await fetch(`${expense_urls.DELETE}/${id}`, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      }
    });
    setExpenses(old => {
      return old.filter(expense => expense.id != id)
    })
  }


    return (
      <ExpenseTable expenses={expenses} deleteExpense={deleteExpense} setExpenses={setExpenses}/>
    );
  }
  
  export default App;
  