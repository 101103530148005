
import { RingProgress } from '@ant-design/plots';
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button, Card, CardHeader, CardBody, Stack, StackDivider, Text, Heading, VStack
} from '@chakra-ui/react'
import { useContext } from 'react';
import { ExpenseContext } from '../tabLayout';

function App() {
  const { expenses } = useContext(ExpenseContext);

  const totals =  {
    tanner: {
      shared: expenses
        .filter(expense => expense.paidBy === 'Tanner' && expense.sharedExpense && expense.sharedCard)
        .reduce((acc, next) => acc + parseFloat(next.value), 0),
      notShared: expenses
        .filter(expense => expense.paidBy === 'Tanner' && expense.sharedExpense && !expense.sharedCard)
        .reduce((acc, next) => acc + parseFloat(next.value), 0),
      personal: expenses
        .filter(expense => expense.paidBy === 'Tanner' && !expense.sharedExpense && !(expense.category == 'Income'))
        .reduce((acc, next) => acc + parseFloat(next.value), 0),
      income: expenses
        .filter(expense => expense.paidBy === 'Tanner' && (expense.category == 'Income'))
        .reduce((acc, next) => acc + parseFloat(next.value), 0),
    },
    heather: {
      shared: expenses
        .filter(expense => expense.paidBy === 'Heather' && expense.sharedExpense && expense.sharedCard)
        .reduce((acc, next) => acc + parseFloat(next.value), 0),
      notShared: expenses
        .filter(expense => expense.paidBy === 'Heather' && expense.sharedExpense && !expense.sharedCard)
        .reduce((acc, next) => acc + parseFloat(next.value), 0),
      personal: expenses
        .filter(expense => expense.paidBy === 'Heather' &&  !expense.sharedExpense && !(expense.category == 'Income'))
        .reduce((acc, next) => acc + parseFloat(next.value), 0),
      income: expenses
        .filter(expense => expense.paidBy === 'Heather' && (expense.category == 'Income'))
        .reduce((acc, next) => acc + parseFloat(next.value), 0),
    }
  }


  const total = (totals.tanner.notShared + totals.tanner.shared + totals.heather.notShared + totals.heather.shared);
  const tannerTotal = totals.tanner.notShared;
  const heatherTotal = (totals.heather.notShared + totals.heather.shared + totals.tanner.shared);
  const tannerShare = (total/3 * 2);
  const heatherShare = (total/3);
  const tannerNet = tannerShare + totals.tanner.personal + totals.tanner.income;
  const heatherNet = heatherShare + totals.heather.personal + totals.heather.income;

  return (
    <Stack spacing={5}>
      <Card>
        <CardHeader>
          <Heading size='md'>Shared Expense Summary</Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              <Heading size='xs'>
                Total Shared Expenses
              </Heading>
              <Stat>
                <StatNumber>${total.toFixed(2)}</StatNumber>
              </Stat>
            </Box>
            <Box>
              <Heading size='xs'>
                Heather Summary
              </Heading>
              <Flex flexWrap="wrap">
                <Stat minWidth={140}>
                  <StatLabel>Share</StatLabel>
                  <StatNumber>${heatherShare.toFixed(2)}</StatNumber>
                </Stat>
                <Stat minWidth={140}>
                  <StatLabel>Paid</StatLabel>
                  <StatNumber>${heatherTotal.toFixed(2)}</StatNumber>
                </Stat>
                <Stat minWidth={140}>
                  <StatLabel>Owed</StatLabel>
                  <StatNumber>${(heatherTotal - heatherShare).toFixed(2)}</StatNumber>
                </Stat>
              </Flex>
            </Box>
            <Box>
              <Heading size='xs'>
                Tanner Summary
              </Heading>
              <Flex flexWrap="wrap">
                <Stat minWidth={140}>
                  <StatLabel>Share</StatLabel>
                  <StatNumber>${tannerShare.toFixed(2)}</StatNumber>
                </Stat>

                <Stat minWidth={140}>
                  <StatLabel>Paid</StatLabel>
                  <StatNumber>${tannerTotal.toFixed(2)}</StatNumber>
                </Stat>

                <Stat minWidth={140}>
                  <StatLabel>Owed</StatLabel>
                  <StatNumber>${(tannerTotal - tannerShare).toFixed(2)}</StatNumber>
                </Stat>
              </Flex>
            </Box>
          </Stack>
        </CardBody>
      </Card>


      <Card>
        <CardHeader>
          <Heading size='md'>Tanner Summary</Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              <Heading size='xs'>
                Tanner's Expenses
              </Heading>
              <Flex>
                <Stat>
                  <StatLabel>Shared Expenses</StatLabel>
                  <StatNumber>${tannerShare.toFixed(2)}</StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Individual</StatLabel>
                  <StatNumber>${totals.tanner.personal.toFixed(2)}</StatNumber>
                </Stat>
              </Flex>
            </Box>
            <Box>
              <Heading size='xs'>
                Tanner's Income
              </Heading>
              <Flex>
                <Stat>
                  <StatNumber>${totals.tanner.income.toFixed(2) * -1}</StatNumber>
                </Stat>
              </Flex>
            </Box>
            <Box>
              <Heading size='xs'>
                Remaining
              </Heading>
              <Stat>
                <StatNumber>${tannerNet.toFixed(2) * -1}</StatNumber>
              </Stat>
            </Box>
          </Stack>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <Heading size='md'>Heather Summary</Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              <Heading size='xs'>
                Heather's Expenses
              </Heading>
              <Flex>
                <Stat>
                  <StatLabel>Shared Expenses</StatLabel>
                  <StatNumber>${heatherShare.toFixed(2)}</StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Individual</StatLabel>
                  <StatNumber>${totals.heather.personal.toFixed(2)}</StatNumber>
                </Stat>
              </Flex>
            </Box>
            <Box>
              <Heading size='xs'>
                Heather's Income
              </Heading>
              <Flex>
                <Stat>
                  <StatNumber>${totals.heather.income.toFixed(2) * -1}</StatNumber>
                </Stat>
              </Flex>
            </Box>
            <Box>
              <Heading size='xs'>
                Remaining
              </Heading>
              <Stat>
                <StatNumber>${heatherNet.toFixed(2) * -1}</StatNumber>
              </Stat>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
}
/*
Shared Expenses
 - Heather Paid
 - Tanner Paid
 - Total
 - Heather's share
 - Tanner's share
Heather's expenses
 - 
74 long
52 height
43 wide




*/

export default App;
  